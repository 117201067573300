<template>
  <div class="containerBody">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEnrolledCounseling">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link :to="{ name: 'counseling' }">
            Counseling
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>{{ pageTitle }}</h1>
      <hr class="separate-line" />
      <ApplicationEssays
        :isEnrolledCounseling="isEnrolledCounseling"
        :studentId="student.id"
        :studentUsername="$route.params.username"
        :sessionClass="sessionClass"
        @deleteEssayDocument="deleteEssayDocument"
        @markStatusAsCompleted="markStatusAsCompleted"
        @cancelStatusAsIncompleted="cancelStatusAsIncompleted"
      />
      <hr class="separate-line" />
      <SchoolEssays
        :isEnrolledCounseling="isEnrolledCounseling"
        :studentId="student.id"
        :sessionClass="sessionClass"
        @deleteEssayDocument="deleteEssayDocument"
        @markStatusAsCompleted="markStatusAsCompleted"
        @cancelStatusAsIncompleted="cancelStatusAsIncompleted"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import ApplicationEssays from "@/views/private/Counseling/EssayComponets/ApplicationEssays";
import SchoolEssays from "@/views/private/Counseling/EssayComponets/SchoolEssays";
import Essay from "@/class/essay";
import essayApi from "@/apis/essay";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    ApplicationEssays,
    SchoolEssays
  },
  data() {
    return {
      student: {},
      sessionClass: {
        course_session: {}
      }
    };
  },
  computed: {
    pageTitle() {
      if (this.student.first_name) {
        return `${this.student.first_name}'s Essays`;
      } else {
        return "";
      }
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    studentUserId() {
      return this.student.id;
    },
    isEnrolledCounseling() {
      return this.$route.name === "counselingEssays";
    }
  },
  async created() {
    this.initialData();
  },
  methods: {
    async initialData() {
      if (this.isEnrolledCounseling) {
        await this.fetchCustomLesson();
      } else {
        await this.fetchUserProfile();
      }
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.student = customLesson.student_class_tickets[0].student;
      this.sessionClass = {
        ...customLesson.session_class
      };
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.student = { ...basic_info, counseling_schools_type };
    },
    async deleteEssayDocument({ studentEssayId, callbackFn }) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await essayApi.deleteApplicationEssayDocument(
          this.studentId,
          studentEssayId
        );
        this.$message.success(this.$t("message.delete_success"));
        callbackFn();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async markStatusAsCompleted({ studentEssay, callbackFn }) {
      if (Essay.isEmptyEssay(studentEssay)) {
        this.$message.error("該作文尚未撰寫任何內容！");
        return;
      }
      try {
        await essayApi.updateStudentEssay(studentEssay.id, { status: 2 });
        this.$message.success(this.$t("message.update_success"));
        callbackFn();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async cancelStatusAsIncompleted({ studentEssay, callbackFn }) {
      try {
        if (Essay.isEmptyEssay(studentEssay)) {
          await essayApi.updateStudentEssay(studentEssay.id, { status: 0 });
        } else {
          await essayApi.updateStudentEssay(studentEssay.id, { status: 1 });
        }
        this.$message.success(this.$t("message.update_success"));
        callbackFn();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style>
.containerBody {
  margin: 0px 120px;
}

.inProgress {
  color: red;
}

.finished {
  color: #42AE6A;
}
</style>